import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData,IMG_URL } = useContext(Context);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState();
  const id = props.show;

  const [formData, setFormData] = useState({
    fire: "",
    waiting_charges: "",
    helpline_no: "",
    waiting_interval_duration:'',
    image: null,
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };
  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedImage = e.target.files[0];

      // Check if a file is selected
      if (selectedImage) {
        // Generate image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);

        // Update formData with the selected image
        setFormData({ ...formData, image: selectedImage });
      } else {
        // User canceled image selection, clear the image preview and formData
        setImagePreview(null);
        setFormData({ ...formData, image: null });
      }
    } else {
      // Handle text input
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = Validation.FormValidation({
      classname: ".AppSetupclass",
    });

    if (validationErrors) {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("fire", formData.fire);
        formDataToSend.append("helpline_no", formData.helpline_no);
        formDataToSend.append("waiting_charges", formData.waiting_charges);
        formDataToSend.append("waiting_interval_duration", formData.waiting_interval_duration);
        formDataToSend.append("free_waiting_time", formData.free_waiting_time);
        formDataToSend.append("image", formData.image);
        const response = await postData(`/masters/app-setup/${id}`, formDataToSend);

        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/app-setup/${id}`);
    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  console.log(formData,'formData formData');
  

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit App Setup</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit App Setup</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="AppSetupclass"
                          >
                            <Row>
                              <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Fare
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="number"
                                                name="fire"
                                                value={formData?.fire}
                                                onChange={handleChange}
                                                placeholder="Fare"
                                                className="input-mandatory"
                                                id="inputEmail3"
                                              />{" "}
                                              <span className="error-message"></span>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Free Waiting Time
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="free_waiting_time"
                                                value={formData?.free_waiting_time}
                                                onChange={handleChange}
                                                placeholder="Free waiting Time"
                                                className="input-mandatory"
                                                id="inputEmail3"
                                              />{" "}
                                              <span className="error-message"></span>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>


                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Waiting Charges
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="number"
                                                name="waiting_charges"
                                                value={
                                                  formData?.waiting_charges
                                                }
                                                onChange={handleChange}
                                                placeholder="Waiting Charges"
                                                className="input-mandatory"
                                                id="inputEmail3"
                                              />{" "}
                                              <span className="error-message"></span>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                        Waiting Interval Duration
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="number"
                                                name="waiting_interval_duration"
                                                value={
                                                  formData?.waiting_interval_duration
                                                }
                                                onChange={handleChange}
                                                placeholder="Waiting Interval Duration"
                                                className="input-mandatory"
                                                id="inputEmail3"
                                              />{" "}
                                              <span className="error-message"></span>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Helpline No
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="number"
                                                name="helpline_no"
                                                value={
                                                  formData?.helpline_no
                                                }
                                                onChange={handleChange}
                                                placeholder="Helpline No"
                                                className="input-mandatory"
                                                id="inputEmail3"
                                              />{" "}
                                              <span className="error-message"></span>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="justify-content-center mt-3">
                            <Form.Label column sm={3}>
                              Image
                            </Form.Label>
                            <Col sm={6}>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    onChange={handleChange}
                                  />
                                </InputGroup>
                                {errors?.image && (
                                  <span style={errorStyle}>
                                    {errors?.image}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                            {/* Left side - Image */}
                            <Col md={4}>
                              {imagePreview ? (
                                <img
                                  src={imagePreview}
                                  alt="Image Preview"
                                  className="table-image"
                                  style={{
                                    height: "100px",
                                    width: "150px",
                                    margin: "4px",
                                    marginLeft: "150px",
                                    backgroundSize: "fixed",
                                  }}
                                />
                              ) : (
                                formData?.image && (
                                  <img
                                    src={IMG_URL + formData.image}
                                    alt={formData?.image || "Image"}
                                    className="table-image"
                                    style={{
                                      height: "100px",
                                      width: "150px",
                                      marginTop: "4px",
                                      marginLeft: "150px",
                                      backgroundSize: "fixed",
                                    }}
                                  />
                                )
                              )}
                            </Col>
                          </Row>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
